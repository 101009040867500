import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';

function GuestGuard({ children }) {
  const account = useSelector((state) => state.account);
  const urlParams = new URLSearchParams(window.location.search);
  const admin_token = urlParams.get('admin_token') || urlParams.get('t');
  const destination = urlParams.get('destination') || '';

  if(admin_token){
    localStorage.setItem('accessToken', admin_token);
    if(destination === "/schedules/dndcalendar"){
        localStorage.setItem("hospitalId", urlParams.get("hospitalId"))
        localStorage.setItem("departmentId", urlParams.get("departmentId"))
        localStorage.setItem("scheduleId", urlParams.get("scheduleId"))
        localStorage.setItem("status", urlParams.get("approve"))
    }
    window.history.pushState({}, document.title, window.location.pathname + destination ? '?destination='+destination : '');
    return window.location.reload();
  }


  if (account.user) {
    if (destination) {
      return <Redirect to={destination} />;}
    return <Redirect to="/app/account" />;
  }

  return children;
}

GuestGuard.propTypes = {
  children: PropTypes.any
};

export default GuestGuard;
