import axios from 'axios';
import { APP_URL } from 'src/constants';

const instance = axios.create({
  baseURL: APP_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
});

export default instance;
