import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import {Menu as MenuIcon} from 'react-feather';
import Logo from 'src/components/Logo';
import {THEMES} from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import './style.css';
import useSettings from "../../../hooks/useSettings";
import {Button} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
        boxShadow: 'none',
        backgroundColor: theme.palette.background.header
      }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
        backgroundColor: theme.palette.background.header
      }
      : {})
  },
  toolbar: {
    minHeight: 64
  },
  logo: {
    width: 42,
    height: 43
  }
}));

function TopBar({className, onMobileNavOpen, ...rest}) {
  const classes = useStyles();
  const { settings, saveSettings } = useSettings();

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon/>
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo className={classes.logo}/>
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1}/>
        <div className="dark-mode-switch">
          <div className="text">
            {
              settings.theme === 'ONE_DARK' ?
                <span>Dark Mode</span>:
                <span>Light Mode</span>
            }
          </div>
          <div className="switch-wrapper">
            <label>
              <input
                type="checkbox"
                defaultChecked={settings.theme === 'ONE_DARK'}
                onChange={e => {
                  saveSettings({
                      ...settings,
                      theme: e.target.checked ? THEMES.ONE_DARK : THEMES.LIGHT
                  })
                }}
              />
              <div className="switch">
                <div className="circle">
                  <div className="moon-spots"></div>
                </div>
                <div className="cloud">
                  <div></div>
                  <div></div>
                </div>
                <div className="stars">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </label>
          </div>
        </div>
        {localStorage.getItem('super_admin_token') != null &&
          <div>
            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                const token = localStorage.getItem('super_admin_token')
                const path = localStorage.getItem('super_admin_path')
                localStorage.removeItem('super_admin_token');
                window.location.href = 'https://admin.medtigo.com/login?admin_token=' + token + '&destination=' + path
              }}
              style={{
                textTransform: 'none',
              }}
            >
              Switch to Super Admin
            </Button>
          </div>
        }
        {/*<Search/>*/}
        {/* <Contacts /> */}
        {/* <Notifications /> */}
        {/*<Settings/>*/}
        <Box ml={2}>
          <Account/>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
